
export class MGUserAuthorityModule {
    mgUserAuthorityID: number = 0;
    mgUserID: number = 0;
    status:   boolean = true;
    mgCompanyAuthorityID: number = 0;
}


export interface MGUserAuthority {
    mgUserAuthorityID: number;
    mgUserID: number;
    status: boolean;
    mgCompanyAuthorityID: number;
}

export class ViewMGUserAuthorityModule {
    mgUserAuthorityID: number = 0;
    mgUserID: number = 0;
    status: boolean = true;
    mgCompanyAuthorityID: number = 0;
    code: string = '';
    }