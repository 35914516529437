import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MGUserAuthority, MGUserAuthorityModule, ViewMGUserAuthorityModule } from './user-authority.module';

@Injectable({
  providedIn: 'root'
})
export class UserAuthorityService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };

    private headers: HttpHeaders;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }

    getCategory(type: string): Observable<MGUserAuthorityModule[]> {
        return this.http.get<MGUserAuthorityModule[]>(environment.apiURL + 'Manage/MGUserAuthority/search/' + type);
    }

    getServiceId(id: number): Observable<MGUserAuthorityModule[]> {
        return this.http.get<MGUserAuthorityModule[]>(environment.apiURL + 'Manage/MGUserAuthority/' + id);
    }

    deleteService(id: number) {
        return this.http.delete(environment.apiURL + 'Manage/MGUserAuthority/' + id).toPromise();
    }

    formData: MGUserAuthorityModule = new MGUserAuthorityModule();
    list: MGUserAuthorityModule[];

    postService() {
        return this.http.post(environment.apiURL + 'Manage/MGUserAuthority/', this.formData);
    }

    putService() {
        return this.http.put(environment.apiURL + 'Manage/MGUserAuthority/' + this.formData.mgUserAuthorityID, this.formData);
    }


    getServiceUserMenu(UserID: number, lang: string) {
        return this.http.get(environment.apiURL + 'Manage/MGUserAuthority/UserMenu/?mgUserID=' + UserID + '&Lang=' + lang, { responseType: 'text' });
    }

    getService(UserID: number): Observable<MGUserAuthorityModule[]> {
        return this.http.get<MGUserAuthorityModule[]>(environment.apiURL + 'Manage/MGUserAuthority/search/' + UserID);
    }

    postListService(userAuthoritList: MGUserAuthority[]) {
        return this.http.post(environment.apiURL + 'Manage/MGUserAuthority/PostList/', userAuthoritList, { responseType: 'text' });
    }

    deleteServiceUserDeleteAll(UserID: number) {
        return this.http.get(environment.apiURL + 'Manage/MGUserAuthority/DeleteList/?mgUserID=' + UserID , { responseType: 'text' });
    }

    getServiceDelete(UserID: number) {
        return this.http.get(environment.apiURL + 'Manage/MGUserAuthority/DeleteList/?mgUserID=' + UserID, { responseType: 'text' });
    }

    getServiceUserID(UserID: number): Observable<ViewMGUserAuthorityModule[]> {
        return this.http.get<ViewMGUserAuthorityModule[]>(environment.apiURL + 'View/ViewMGUserAuthority/search/' + UserID);
    }
}
