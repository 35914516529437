import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [


      {
          name: 'الرئيسية',
          type: 'link',
          icon: 'home',
          state: 'master/dashboard'
      },
      {
          name: 'الأساسيات',
          type: 'dropDown',
          icon: 'content_copy',
          state: '',
          sub: [
              { name: 'السورة', state: 'master/chapter' },
              { name: 'الولاية', state: 'master/wilayat' },
              { name: 'المستوى', state: 'master/level' },
              { name: 'موقع التلاوة', state: 'master/location' },
              { name: 'المدرسة', state: 'master/school' },
              { name: 'السنة الدراسية', state: 'master/academic_year' },
              { name: 'الحافلات', state: 'master/bus' },
              { name: 'المجموعات', state: 'master/group' },
              { name: 'المعلمين', state: 'main/teacher'},
              { name: 'الطلاب', state: 'main/student'},
              { name: 'نوع الاستفسار', state: 'master/enquiry_type' },
              { name: 'الصفحات', state: 'web-tools/webpage' }
          ]
      },

      {
          name: 'التسجيل',
          type: 'link',
          icon: 'description',
          state: 'main/registration'
      },
      {
          name: 'طلاب السنة',
          type: 'link',
          icon: 'description',
          state: 'main/student_year'
      },{
          name: 'حلقات التلاوة',
          type: 'link',
          icon: 'description',
          state: 'main/recitation_lectures'
      },{
          name: 'حلقة الحفظ',
          type: 'link',
          icon: 'description',
          state: 'main/memorization_lecture'
      }, {
          name: 'التلاوة الفردية',
          type: 'link',
          icon: 'description',
          state: 'main/individual_recitation'
      },{
          name: 'الاختبار التجهيزي',
          type: 'link',
          icon: 'description',
          state: 'main/preparatory_exam'
      },{
          name: 'الاختبار الكتابي',
          type: 'link',
          icon: 'description',
          state: 'main/written_exam'
      },{
          name: 'الاختبار الشفوي',
          type: 'link',
          icon: 'description',
          state: 'main/oral_exam'
      },

      //{
      //    name: 'تقرير السؤال',
      //    type: 'link',
      //    icon: 'description',
      //    state: 'main/question-report'
      //},

      {
          name: 'الاستفسارات',
          type: 'link',
          icon: 'description',
          state: 'web-tools/feedback'
      },
      {
          name: 'الإدارة',
          type: 'dropDown',
          icon: 'person',
          state: '',
          sub: [
              { name: 'معلومات المؤسسة', state: 'manage/company_info' },
              { name: 'ضبط الشركة', state: 'manage/company_settings' },
              { name: 'نماذج رسائل الواتساب', state: 'master/template' },
              { name: 'إرسال رسائل الواتساب', state: 'manage/send_bulk_message' },
              { name: 'تقرير رسائل الواتساب', state: 'manage/message' },
              { name: 'المستخدمين', state: 'manage/users' },
              { name: 'الاشتراطات المالية', state: 'master/terms' },
            /*  { name: 'مراقب المستخدمين', state: 'manage/user_monitor' },*/
              { name: 'تقرير الزوار', state: 'web-tools/visitor-report' },
              { name: 'سؤال المسابقة', state: 'main/question' }
              //{ name: 'ضبط التسجيل', state: 'manage/registration_Settings' }
          ]
      }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
        // this.menuItems.next(this.separatorMenu);
        // break;
    //   case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        // break;
    //   default:
        // this.menuItems.next(this.plainMenu);
    // }
  }
}
