import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
    template: `<h1 matDialogTitle class="mb-8" dir='rtl'>{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16" dir='rtl'>{{ data.message }}</div>
    <div mat-dialog-actions class="pb-16" dir='rtl'>
    <button
    type="button"
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(true)">نعم</button>
    &nbsp;
    <span fxFlex></span>
    <button
    type="button"
    color="accent"
    mat-raised-button
    (click)="dialogRef.close(false)">لا</button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
}